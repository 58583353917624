import React from 'react'
import presidentImg from "../media/4904d7e3-512c-408b-bad4-2d3c7bb32e3a.jpg"
import presidentImg2 from "../media/IMG_1479.jpg"
import secyImg3 from "../media/WhatsApp Image 2025-03-27 at 12.18.02 AM.jpeg"
import PersonCard from './PersonCard'
import p1 from "../media/people/1.jpg"
import adFac from '../data/adFac'
import adStu from '../data/adStu'
import finFac from '../data/finFac'
import finStu from '../data/finStu'
import cultFac from '../data/cultFac';
import cultStu from '../data/cultStu';
import eventFac from '../data/eventFac';
import eventStu from '../data/eventStu';
import pujaFac from '../data/pujaFac';
import pujaStu from '../data/pujaStu';
import foodFac from '../data/foodFac';
import foodStu from '../data/foodStu';
import stageFac from '../data/stageFac';
import stageStu from '../data/stageStu';
import alumFac from '../data/alumFac';
import alumStu from '../data/alumStu';
import webFac from '../data/webFac';
import webStu from '../data/webStu';

import OrganizerCard from './OrganizerCard'

const Organizers = () => {
  const president={ name: "Premalata Jena, President", department: "Electrical Engineering", img: presidentImg, email: "premalata.jena@ee.iitr.ac.in, premalata.jena@gmail.com" }
  const studentPresident={name: "Mr.Anil Kumar Barik, Student President", department: "Civil Engineering", img: presidentImg2, email: "anil.vssut.1995@gmail.com" }
  const studentJointSecy1={name: "Ms. Swayamshree Barik, Student Joint Secy", department: "", img: secyImg3, email: "" }
  const studentJointSecy2={name: "Ms. Bishnupriya Swain, Student Joint Secy", department: "", img: p1, email: "" }

  return (
    <div className='contents'>
      <h1>Odia Parivar Organizing Body for 2023-24</h1>
      <hr></hr>
      <div className=' people'>
       
            <OrganizerCard
            name={president.name}
            img={president.img}
            dept={president.department}
            email={president.email}
            size="large"
            />
            <OrganizerCard
            name={studentPresident.name}
            img={studentPresident.img}
            dept={studentPresident.department}
            email={studentPresident.email}
            size="large"

            />
            <OrganizerCard
            name={studentJointSecy1.name}
            img={studentJointSecy1.img}
            dept={studentJointSecy1.department}
            email={studentJointSecy1.email}
            size="large"

            />
            <OrganizerCard
            name={studentJointSecy2.name}
            img={studentJointSecy2.img}
            dept={studentJointSecy2.department}
            email={studentJointSecy2.email}
            size="large"

            />
        
        </div>

        {/* //////////////////////////////////////////////////////////// */}
        <h3 style={{"fontWeight":"600"}}>Advisory Team</h3>
        <h4>Faculty In-charge</h4>
        <div className="people">
        {adFac.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"
          />
        )}
        </div>
        <h4>Student In-charge</h4>
        <div className="people">
        {adStu.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>

        {/* //////////////////////////////////////////////////////////// */}
        <h3 style={{"fontWeight":"600"}}>Finance Secion Team</h3>
        <h4>Faculty In-charge</h4>
        <div className="people">
        {finFac.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>
        <h4>Student In-charge</h4>
        <div className="people">
        {finStu.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>

        {/* //////////////////////////////////////////////////////////// */}
        <h3 style={{"fontWeight":"600"}}>Cultural Team</h3>
        <h4>Faculty In-charge</h4>
        <div className="people">
        {cultFac.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>
        <h4>Student In-charge</h4>
        <div className="people">
        {cultStu.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>

        {/* //////////////////////////////////////////////////////////// */}
        <h3 style={{"fontWeight":"600"}}>Event Management Team</h3>
        <h4>Faculty In-charge</h4>
        <div className="people">
        {eventFac.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>
        <h4>Student In-charge</h4>
        <div className="people">
        {eventStu.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>

        {/* //////////////////////////////////////////////////////////// */}
        <h3 style={{"fontWeight":"600"}}>Puja Management Team</h3>
        <h4>Faculty In-charge</h4>
        <div className="people">
        {pujaFac.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>
        <h4>Student In-charge</h4>
        <div className="people">
        {pujaStu.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>

        {/* //////////////////////////////////////////////////////////// */}
        <h3 style={{"fontWeight":"600"}}>Food Management Team</h3>
        <h4>Faculty In-charge</h4>
        <div className="people">
        {foodFac.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>
        <h4>Student In-charge</h4>
        <div className="people">
        {foodStu.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>

        {/* //////////////////////////////////////////////////////////// */}
        <h3 style={{"fontWeight":"600"}}>Stage Management Team</h3>
        <h4>Faculty In-charge</h4>
        <div className="people">
        {stageFac.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>
        <h4>Student In-charge</h4>
        <div className="people">
        {stageStu.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>

        {/* //////////////////////////////////////////////////////////// */}
        <h3 style={{"fontWeight":"600"}}>Alumni Management Team</h3>
        <h4>Faculty In-charge</h4>
        <div className="people">
        {alumFac.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>
        <h4>Student In-charge</h4>
        <div className="people">
        {alumStu.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>

        {/* //////////////////////////////////////////////////////////// */}
        <h3 style={{"fontWeight":"600"}}>Website Management Team</h3>
        <h4>Faculty In-charge</h4>
        <div className="people">
        {webFac.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>
        <h4>Student In-charge</h4>
        <div className="people">
        {webStu.map((person)=>
          <OrganizerCard
          name={person.name}
          img={person.img}
          dept={person.department}
          email={person.email}
          size="small"

          />
        )}
        </div>
        
    </div>
  )
}

export default Organizers


const finFac = [
    {
      name: "Mr. Anil Kumar Barik",
    },
    {
      name: "Ms. Swyamshree Barik",
    }
  ];
  
  export default finFac;

const cultStu = [
    { name: "Ms. Bishnupriya Swain" },
    { name: "Ms. Sushree Paritwesha pradhana" },
    { name: "Ms. Anshika" },
    { name: "Ms. Shruti Pragyan Tripathy" },
    { name: "Ms. Adyasha Jena" }
  ];
  
  export default cultStu;
  

import p1 from "../media/people/1.jpg"
import abhishekKumarbehra from "../media/people/Faculty photos/Abhishek Behera.JPG"
import abhishekSamantray from "../media/people/Faculty photos/abhishek samantray.JPG"
import abinashSwain from "../media/people/Faculty photos/abinash swain.JPG"
import amalenduPatnaik from "../media/people/Faculty photos/Amalendu patnaik.JPG"
import ameeyaKNayak from "../media/people/Faculty photos/ameeya k nayak.JPG"
import anindyaJMishra from "../media/people/Faculty photos/Anindya J Mishra.JPG"
import aruniMohaptra from "../media/people/Faculty photos/Aruni mohaptra.JPG"
import prasadDas from "../media/people/Faculty photos/bishnu prasad das.JPG"
import dBharat from "../media/people/Faculty photos/D bharat.JPG"
import debiPrasannaSahoo from "../media/people/Faculty photos/debi prasanna sahoo.JPG"
import diptimayeeNayak from "../media/people/Faculty photos/diptimayee nayak.JPG"
import elloraPadhi from "../media/people/Faculty photos/Ellorapadhy.png"
import jagdishPSahoo from "../media/people/Faculty photos/jagdish p sahoo.JPG"
import jogendraKumarNayak from "../media/people/Faculty photos/jogendra kumar nayak.JPG"
import kirtiBhusanMishra from "../media/people/Faculty photos/kirti bhusan mishra.JPG"
import lalatenduKDas from "../media/people/Faculty photos/lalatendu k das.JPG"
import manoranjanParida from "../media/people/Faculty photos/manoranjan Parida.JPG"
import mohitMohanty from "../media/people/Faculty photos/mohit prakash mohanty.JPG"
import npPadhy from "../media/people/Faculty photos/N P Padhy.JPG"
import pKSahoo from "../media/people/Faculty photos/P K sahoo.JPG"
import paritoshMohanty from "../media/people/Faculty photos/paritosh mohanty.jpg"
import pitambarPati from "../media/people/Faculty photos/Pitambar pati.JPG"
import pranitaSarangi from "../media/people/Faculty photos/pranita p sarangi.JPG"
import pratapChandraMohanty from "../media/people/Faculty photos/pratap chandra mohanty.JPG"
import premalataJena from "../media/people/Faculty photos/premalata jena.JPG"
import pyariMohanPradhan from "../media/people/Faculty photos/pyari mohan pradhan.JPG"
import rajibKumarPanigrahi from "../media/people/Faculty photos/rajib kumar panigrahi.JPG"
import ramanujaPanigrahi from "../media/people/Faculty photos/ramanuja panigrahi.JPG"
import saradaPPradhan from "../media/people/Faculty photos/sarada p pradhan.JPG"
import sharmiliDas from "../media/people/Faculty photos/Sharmili das.JPG"
import sibaPatro from "../media/people/Faculty photos/siba patro.JPG"
import simanchalPadhy from "../media/people/Faculty photos/simanchal padhy.JPG"
import sonalishaRay from "../media/people/Faculty photos/sonalisha ray.JPG"
import sudhakarSubudhi from "../media/people/Faculty photos/sudhakar subudhi.JPG"
import sumeetMishra from "../media/people/Faculty photos/sumeet mishra.JPG"
import ushaLenka from "../media/people/Faculty photos/usha lenka.JPG"
import falguniPattnaik from "../media/people/Faculty photos/falguni pattanaik.png"


const people = [
  { name: "Abinash Swain", department: "Mechanical & Industrial Engineering", imgsrc: abinashSwain, email: "abinash.swain@me.iitr.ac.in" },
  { name: "Abhisek Kumar Behera", department: "Electrical Engineering", imgsrc: abhishekKumarbehra, email: "abhisek@ee.iitr.ac.in" },
  { name: "Abhishek Samantray", department: "Humanities and Social Sciences", imgsrc: abhishekSamantray, email: "abhishek.samantray@hs.iitr.ac.in" },
  { name: "AMALENDU PATNAIK", department: "Electronics and Communication Engineering", imgsrc: amalenduPatnaik, email: "amalendu.patnaik@ece.iitr.ac.in" },
  { name: "Ameeya Kumar Nayak", department: "Mathematics", imgsrc: ameeyaKNayak, email: "ameeya.nayak@ma.iitr.ac.in" },
  { name: "Anindya Jayanta Mishra", department: "Humanities and Social Sciences", imgsrc: anindyaJMishra, email: "anindya.mishra@hs.iitr.ac.in" },
  { name: "Aruni Mahapatra", department: "Humanities and Social Sciences", imgsrc: aruniMohaptra, email: "aruni.mahapatra@hs.iitr.ac.in" },
  { name: "BISHNU PRASAD DAS", department: "Electronics and Communication Engineering", imgsrc: prasadDas, email: "bishnu.das@ece.iitr.ac.in" },
  { name: "Diptimayee Nayak", department: "Humanities and Social Sciences", imgsrc: diptimayeeNayak, email: "dipti.nayak@hs.iitr.ac.in" },
  { name: "Ellora Padhi", department: "Water Resources Development (WRD)", imgsrc: elloraPadhi, email: "ellora.padhi@wr.iitr.ac.in" },
  { name: "Falguni Pattanaik", department: "Humanities and Social Sciences", imgsrc: falguniPattnaik, email: "falguni.pattanaik@hs.iitr.ac.in" },
  { name: "Jogendra Kumar Nayak", department: "Department of Management Studies", imgsrc: jogendraKumarNayak, email: "jogendra.nayak@ms.iitr.ac.in" },
  { name: "Lalatendu Keshari Das", department: "Humanities and Social Sciences", imgsrc: lalatenduKDas, email: "lkdas@hs.iitr.ac.in" },
  { name: "Mohit Prakash Mohanty", department: "Dept. of Water resources Development and Management", imgsrc: mohitMohanty, email: "mohit.mohanty@wr.iitr.ac.in", phone: "7738705864" },
  { name: "Monoranjan Parida", department: "Civil Engineering", imgsrc: manoranjanParida, email: "mprdafce@iitr.ac.in, mparida@gmail.com" },
  { name: "N.P. Padhy", department: "Electrical Engineering", imgsrc: npPadhy, email: "nppadhy@ee.iitr.ac.in" },
  { name: "Paritosh Mohanty", department: "Department of Chemistry", imgsrc: paritoshMohanty, email: "pm@cy.iitr.ac.in, paritosh75@gmail.com" },
  { name: "Pitambar Pati", department: "Earth Science", imgsrc: pitambarPati, email: "pitambar.pati@es.iitr.ac.in" },
 
  { name: "Pranita P. Sarangi", department: "Bioscience", imgsrc: pranitaSarangi, email: "pranita.sarangi@bt.iitr.ac.in" },
  { name: "Pratap Chandra Mohanty", department: "Humanities and Social Sciences", imgsrc: pratapChandraMohanty, email: "pratap.mohanty@hs.iitr.ac.in" },
  { name: "Premalata Jena", department: "Electrical Engineering", imgsrc: premalataJena, email: "premalata.jena@ee.iitr.ac.in, premalata.jena@gmail.com" },
  { name: "PYARI MOHAN PRADHAN", department: "Electronics and Communication Engineering", imgsrc: pyariMohanPradhan, email: "pmpradhan@ece.iitr.ac.in" },
  { name: "RAJIB KUMAR PANIGRAHI", department: "Electronics and Communication Engineering", imgsrc: rajibKumarPanigrahi, email: "rajib.panigrahi@ece.iitr.ac.in" },
  { name: "Ramanuja Panigrahi", department: "Electrical Engineering", imgsrc: ramanujaPanigrahi, email: "ramanujap@ee.iitr.ac.in" },
  { name: "Sarada Prasad Pradhan", department: "Earth Science", imgsrc: saradaPPradhan, email: "sppradhan@es.iitr.ac.in" },
 
  { name: "Siba Kumar Patro", department: "Electrical Engineering", imgsrc: sibaPatro, email: "skpatro@ee.iitr.ac.in" },
  { name: "Simanchal Padhy", department: "Earth Science", imgsrc: simanchalPadhy, email: "spadhy@es.iitr.ac.in" },
  { name: "Sonalisa Ray", department: "Civil Engineering", imgsrc: sonalishaRay, email: "sonalisa.ray@ce.iitr.ac.in" },
  { name: "Sudhakar Subudhi", department: "Mechanical & Industrial Engineering", imgsrc: sudhakarSubudhi, email: "sudhakar.subudhi@me.iitr.ac.in" },
  { name: "Sumeet Mishra", department: "Metallurgical and Materials Engineering Department", imgsrc: sumeetMishra, email: "sumeet.mishra@mt.iitr.ac.in" },
  { name: "Usha Lenka", department: "Department of Management Studies", imgsrc: ushaLenka, email: "usha.lenka@ms.iitr.ac.in" },
];
export default people;

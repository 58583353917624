const stageStu = [
    { name: "Mr. Anil Kumar Barik" },
    { name: "Ms. Shruti Pragyan Tripathy" },
    { name: "Ms. Bishnupriya Swain" },
    { name: "Ms. Shreya" },
    { name: "Ms. Shreemayi Padhy" },
    { name: "Ms. Anshika" }
];

export default stageStu;

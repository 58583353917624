const adStu=[
    {
        name: "Mr. Barada Prasanna Nayak",
        department: "", // Department not provided
        img: "", // Leave this blank for now
        email: "", // Fill this with appropriate email address
        phone: "", // Fill this with appropriate phone number
    },
    {
        name: "Mr. Jyoti Ranjan Sahoo",
        department: "", // Department not provided
        img: "",
        email: "",
        phone: "",
    },
    {
        name: "Mr. Avinash Pradhana",
        department: "", // Department not provided
        img: "",
        email: "",
        phone: "",
    },
    {
        name: "Mr. Jyoti Ranjan Dash",
        department: "", // Department not provided
        img: "",
        email: "",
        phone: "",
    },
    {
        name: "Mr. Prasanta Kumar Mohanty",
        department: "", // Department not provided
        img: "",
        email: "",
        phone: "",
    }
]

export default adStu;
